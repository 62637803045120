import React from 'react';

import { ButtonLink } from '../components/button-link';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

const ContactFormSuccessPage: React.FC = () => (
  <Layout>
    <SEO title="Form Success" />
    <div className="py-20 text-center">
      <h1 className="mb-4 text-5xl">Thank you for your message!</h1>
      <h2 className="mb-8 text-xl">You should expect a response shortly.</h2>
      <h3 className="text-lg">
        <ButtonLink to="/" title="Head Back Home" />
      </h3>
    </div>
  </Layout>
);

export default ContactFormSuccessPage;
